import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="foot">
                    <p>&copy; Group 9-CSE5335. ALL Rights Reserved. Diversity Connect</p>
                </footer>
            </div>
        )
    }
}
